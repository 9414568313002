const fetchJobData = async (jobId) => {
  const url = `/nonprod/analyze/async/${jobId}`;
  console.log("Fetch job status: ", url);
  const response = await fetchMethod(url, "GET");

  if (!response.ok) {
    throw new Error(`Error fetching job status: ${response.statusText}`);
  }

  return await response.json();
};

const fetchJobHistory = async () => {
  const response = await fetchMethod("/nonprod/review/user", "GET");

  if (!response.ok) {
    throw new Error(`Error fetching job history: ${response.statusText}`);
  }

  return await response.json();
};

const fetchRequestAnalysis = async (fileName, analysis) => {
  const url = new URL("/nonprod/analyze/async/", window.location.href);

  url.searchParams.append("file-name", fileName);
  url.searchParams.append("analysis", analysis);

  const response = await fetchMethod(url.toString(), "GET");

  if (!response.ok) {
    const error_msg = await response.json();
    console.error(`Error starting analysis: ${JSON.stringify(error_msg)}`);
    throw new Error();
  }

  return await response.json();
};

const fetchAuthentToken = async (loginData) => {
  const response = await fetchMethod("/nonprod/auth/login", "POST", loginData);
  if (!response.ok) {
    throw new Error("Login failed");
  }

  return await response.json();
};

const fetchMethod = async (url, method, body = null) => {
  const authentHeader = localStorage.getItem("authToken")
    ? `Bearer ${localStorage.getItem("authToken")}`
    : null;

  const response = await fetch(url, {
    method: method,
    headers: {
      Authorization: authentHeader,
      "Content-Type": "application/json",
      "Cache-Control": "max-age=0, must-revalidate, no-cache, no-store",
      Pragma: "no-cache", // For older HTTP/1.0 servers
      Expires: "0", // Ensures the request is always fresh
    },
    body: body && JSON.stringify(body),
  });

  return response;
};

const parseFetchUrls = (fetchUrls) => {
  if (typeof fetchUrls !== "string") {
    return fetchUrls;
  }

  try {
    return JSON.parse(fetchUrls);
  } catch (error) {
    console.error("Error parsing fetch URLs:", error);
    return [];
  }
};

const selectDownloadUrls = (fetchUrls) => {
  if (!fetchUrls || fetchUrls.length === 0) {
    return null;
  }

  // If more than one URL:
  // - find the first URL that does not contain "out.json"
  // - if none found, return null
  const url = fetchUrls.find((url) => !url.includes("out.json"));
  const result = url || null;
  return result;
};

const parseAndSelectUrlOrNull = (fetchUrls) => {
  const listUrls = parseFetchUrls(fetchUrls);
  return selectDownloadUrls(listUrls);
};

export {
  fetchJobData,
  fetchJobHistory,
  fetchRequestAnalysis,
  fetchAuthentToken,
  fetchMethod,
  parseAndSelectUrlOrNull,
};
