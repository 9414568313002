import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";
import {
  fetchJobData,
  fetchJobHistory,
  parseAndSelectUrlOrNull,
} from "../utils/Api";

const Results = () => {
  const navigate = useNavigate();

  // TODO: This is really ugly, need modularity
  const [currentPage, setCurrentPage] = useState(1);
  const [inProcessJobId, setInProcessJobId] = useState([]);
  const [jobMap, setJobMap] = useState([]);
  const [jobHistoryResolved, setJobHistoryResolved] = useState(false);
  const cardsPerPage = 10;

  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  };

  const jobNameFromUrl = (url) => {
    if (!url) {
      return null;
    }

    // the url is structured as such:
    // https://.../.../video_name.json?.....
    try {
      return url.split("?")[0].split("/").pop().split(".")[0];
    } catch (error) {
      console.error("Error parsing job name from URL:", error);
      return null;
    }
  };

  const formatJobData = (job) => {
    return {
      id: job.id,
      status: job.job_status,
      date: parseDate(job.created_at),
      fetch_url: parseAndSelectUrlOrNull(job.fetch_url),
    };
  };

  const giveNameToJob = (job) => {
    const nameFromUrl = jobNameFromUrl(job.fetch_url);
    return {
      ...job,
      name: nameFromUrl,
    };
  };

  const isFinished = (job) => {
    // a job is finished when it has a name
    // (a filename from fetch_url, which is not 'out.json')
    const result = job.name !== null;
    return result;
  };

  useEffect(() => {
    const dataToJobHistory = (userHistoryList) => {
      const historyList = userHistoryList
        .map(formatJobData)
        //.filter((job) => job.fetch_url && job.date)
        .map(giveNameToJob);
      //.filter((job) => job.name);

      const inProcessIdList = historyList
        .filter((job) => !isFinished(job))
        .map((job) => job.id);

      setInProcessJobId(inProcessIdList);

      if (historyList.length === 0) {
        return {};
      }

      const result = historyList.reduce((acc, job) => {
        acc[job.id] = job;
        return acc;
      }, {});

      return result;
    };

    const fetchAndProcessJobHistory = async () => {
      try {
        const responseJson = await fetchJobHistory();
        const dataHistoryList = responseJson.data;

        const jobHistoryMap = dataToJobHistory(dataHistoryList);
        setJobMap(jobHistoryMap);
        setJobHistoryResolved(true);
      } catch (error) {
        console.error("Error fetching job status:", error);
      }
    };

    fetchAndProcessJobHistory();
  }, []);

  useEffect(() => {
    const cleanInProcessList = () => {
      // remove jobs that are no longer in process
      const newInProcessList = inProcessJobId.filter(
        (jobId) => !isFinished(jobMap[jobId])
      );

      setInProcessJobId(newInProcessList);
    };

    const updateInProcess = async () => {
      await Promise.all(
        inProcessJobId.map(async (jobId) => {
          const data = await fetchJobData(jobId);

          if (data) {
            jobMap[jobId].status = data.job_status;

            if (data.job_status === "SUCCEEDED" && data.fetch_url) {
              const url = parseAndSelectUrlOrNull(data.fetch_url);
              jobMap[jobId].name = jobNameFromUrl(url);
              jobMap[jobId].fetch_url = url;
            }
          }

          return jobId;
        })
      );
    };

    const interval = setInterval(() => {
      updateInProcess();

      cleanInProcessList();
    }, 3000);

    return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
  }, [inProcessJobId]);

  const jobHistoryCardFunc = () => {
    const cardElems = Object.values(jobMap)
      .sort((a, b) => b.date - a.date)
      .map((job) => <Card key={job.id} job={job} />);

    return cardElems;
  };

  const renderNoCards = () => {
    return (
      <div className="no-record-title">
        <span className="no-record-text">
          No record found. Upload a video and start a new analysis !
        </span>
      </div>
    );
  };

  const renderCards = () => {
    const allCards = jobHistoryCardFunc();

    // FOR DEV purposes:
    // repeat multiple times currentJobCardElem to simulate multiple cards
    //const devHistoryCardElems = Array(12).fill(historyCardElems).flat();
    //console.log("total devHistoryCardElems: ", devHistoryCardElems.length);

    const cardElems = allCards.filter((elem) => elem);

    if (cardElems.length === 0 && jobHistoryResolved) {
      return renderNoCards();
    }

    // Pagination logic
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cardElems.slice(indexOfFirstCard, indexOfLastCard);
    const paginationLength = Math.ceil(cardElems.length / cardsPerPage);

    const cardPaginationElem = (
      <div>
        {paginationLength > 1 && (
          <div className="pagination">
            {Array.from({ length: paginationLength }, (_, index) => (
              <button
                key={`page-${index}`}
                onClick={() => setCurrentPage(index + 1)}
                className={index + 1 === currentPage ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
        <div className="cards-container">{currentCards}</div>
      </div>
    );

    return cardPaginationElem;
  };

  const goToUpload = () => {
    navigate("/upload");
  };

  return (
    <div>
      <Navbar />
      <div>
        <h1 className="data-title">
          My <span className="green-text">data</span> record
        </h1>
        {renderCards()}
      </div>
      <div className="footer-container">
        <div className="footer-left">
          <a onClick={goToUpload} className="nav-link">
            Back
          </a>
        </div>
      </div>
    </div>
  );
};

export default Results;

//<div className="no-analysis">
//  <p style={{ color: "white" }}>No analysis yet !</p>
//</div>
